import React, { useEffect, useRef, useState } from 'react'

import useDebounce from '../hooks/useDebounce'

import {
    IonButton,
    IonButtons,
    IonCard,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react'

import { addOutline, closeCircleOutline } from 'ionicons/icons'
import api from '../api'

interface CarTypes {
    id: 13
    vin: string
    gos_number: string
    brand: string
    name: string
    img: string
    year: number
}

// interface CarsTypes {
//     items: CarTypes[]
// }

const Garage: React.FC = () => {
    const page = useRef(null)

    const [parentElement, setParentElement] = useState<HTMLElement | null>(page.current)

    useEffect(() => {
        setParentElement(page.current)
    }, [])

    const [cars, setCars] = useState<CarTypes[]>([])

    const [toggleForm, setToggleForm] = useState(false)
    const [carVinNumber, setCarVinNumber] = useState('')
    const [carGovNumber, setCarGovNumber] = useState('')
    const [carBrand, setCarBrand] = useState('')
    const [carModel, setCarModel] = useState('')
    const [carYear, setCarYear] = useState(null)

    const parserValue = useDebounce(carVinNumber, 250)

    const clearForm = () => {
        setCarBrand('')
        setCarModel('')
        setCarGovNumber('')
        setCarVinNumber('')
    }

    const changeCarVinNumber = (e: Event) => {
        const value = (e.target as HTMLIonInputElement).value as string

        setCarVinNumber(value)
    }

    const changeCarGovNumber = (e: Event) => {
        const value = (e.target as HTMLIonInputElement).value as string

        setCarGovNumber(value)
    }

    const changeCarBrand = (e: Event) => {
        const value = (e.target as HTMLIonInputElement).value as string

        setCarBrand(value)
    }

    const changeCarModel = (e: Event) => {
        const value = (e.target as HTMLIonInputElement).value as string

        setCarModel(value)
    }

    const changeCarYear = (e: Event) => {
        const value = (e.target as HTMLIonInputElement).value as number

        // @ts-ignore
        setCarYear(value)
    }

    // const validateForm =
    //     carBrand.length > 0 && carModel.length > 0 && carGovNumber.length > 0 && carVinNumber.length > 0 && carYear

    const openForm = () => setToggleForm(true)
    const closeForm = () => {
        setToggleForm(false)
        clearForm()
    }

    const addCar = async () => {
        const payload = {
            vin: carVinNumber,
            gos_number: carGovNumber,
            brand: carBrand,
            name: carModel,
            year: carYear
        }

        await api
            .put(`/cars`, payload, { withCredentials: true })
            .then(() => {
                fetchCars()
            })
            .catch((err) => console.log(err))

        closeForm()
    }

    const removeCar = async (id: number) => {
        await api
            .delete(`/cars/${id}`)
            .then(() => {
                fetchCars()
            })
            .catch((err) => console.log(err))
    }

    const parseCar = async () => {
        const payload = {
            vin: carVinNumber
        }

        await api
            .post(`/cars/parser`, payload, { withCredentials: true })
            .then((res) => {
                setCarBrand(res.data.brand)
                setCarModel(res.data.name)
                setCarYear(res.data.year)
            })
            .catch((err) => console.log(err))
    }

    const fetchCars = async () => {
        await api
            .get(`/cars`)
            .then((res) => {
                console.log(res.data)
                setCars(res.data.items)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchCars()
    }, [])

    useEffect(() => {
        parseCar()
    }, [parserValue])

    return (
        <IonPage ref={page}>
            <IonToolbar>
                <IonTitle>Гараж</IonTitle>
                <IonButtons slot='primary'>
                    <IonButton onClick={openForm}>
                        <IonIcon slot='icon-only' icon={addOutline}></IonIcon>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent fullscreen>
                <div className='flex flex-col'>
                    {cars.map((car) => (
                        <IonCard key={car.id} className='relative mb-0 flex ion-padding'>
                            <div className='mr-3 flex h-20 w-20 shrink-0 items-center justify-center rounded-lg'>
                                <img src={car.img} alt='' />
                            </div>
                            <div className='flex flex-col'>
                                <span className='font-semibold mb-auto line-clamp-2'>
                                    {car.brand} {car.name} ({car.year})
                                </span>
                                <div className='text-sm text-theme-gray-500'>
                                    <span className='font-semibold'>VIN:</span> {car.vin}
                                </div>
                                <span className='text-sm text-theme-gray-500'>
                                    <span className='font-semibold'>Гос номер:</span> {car.gos_number}
                                </span>
                            </div>
                            <div className='absolute top-2 right-2'>
                                <IonIcon
                                    icon={closeCircleOutline}
                                    className='text-xl text-red-500'
                                    onClick={() => removeCar(car.id)}
                                />
                            </div>
                        </IonCard>
                    ))}
                </div>
                <IonModal
                    isOpen={toggleForm}
                    presentingElement={parentElement!}
                    canDismiss={true}
                    onDidDismiss={closeForm}
                >
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot='start'>
                                <IonButton onClick={closeForm}>Назад</IonButton>
                            </IonButtons>
                            <IonTitle>Информация</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className='text-black'>
                        <IonList className='pr-4'>
                            <IonItem>
                                <IonLabel>VIN</IonLabel>
                                <IonInput
                                    value={carVinNumber}
                                    placeholder='HR33-083020'
                                    onIonInput={changeCarVinNumber}
                                ></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Марка</IonLabel>
                                <IonInput value={carBrand} placeholder='BMW' onIonInput={changeCarBrand}></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Модель</IonLabel>
                                <IonInput value={carModel} placeholder='X5' onIonInput={changeCarModel}></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Год</IonLabel>
                                <IonInput
                                    value={carYear}
                                    type='number'
                                    placeholder='2016'
                                    onIonInput={changeCarYear}
                                ></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position='floating'>Гос. номер</IonLabel>
                                <IonInput
                                    clearInput={true}
                                    value={carGovNumber}
                                    onIonInput={changeCarGovNumber}
                                ></IonInput>
                            </IonItem>
                        </IonList>
                        <div className='flex flex-col ion-padding'>
                            <img
                                src={require('../assets/images/car_id_example.jpg')}
                                alt=''
                                style={{ width: '100%' }}
                            />
                            <IonButton className='mt-8 w-full' onClick={addCar}>
                                Добавить
                            </IonButton>
                        </div>
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    )
}

export default Garage
