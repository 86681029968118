import { useState, FormEvent } from 'react'

export interface InputTypes {
    value: string | number
    text?: string
    textColor?: string
    placeholder?: string
    type?: string
    required?: boolean
    pattern?: string
    error?: boolean
    errorMessage?: string
    className?: string
    children?: React.ReactNode
    getValue: (i: string) => void
    handleBlur?: (i: string | number) => void
    handleFocus?: (i: any) => void
    handleClick?: (i: any) => void
}

const Input = ({
    value,
    text,
    textColor = 'text-theme-blue',
    placeholder,
    type = 'text',
    required,
    pattern,
    error,
    errorMessage,
    className = '',
    children,
    getValue,
    handleBlur,
    handleFocus,
    handleClick
}: InputTypes) => {
    const [inputFocus, setInputFocus] = useState(false)
    const [requiredState, setRequired] = useState(required)

    const spanClass =
        value || inputFocus
            ? `-translate-y-2 text-sm top-0 ${textColor}`
            : '-translate-y-1/2 top-1/2 text-theme-gray-500'

    const triggerBlur = (e: any) => {
        setInputFocus(false)
        if (handleBlur) handleBlur(e.target.value)
        if (!value && requiredState === false) {
            setRequired(true)
        }
    }

    const triggerFocus = (e: any) => {
        setInputFocus(true)
        if (handleFocus) handleFocus(e.target.value)
        if (requiredState === true) {
            setRequired(false)
        }
    }

    const parseValue = (e: FormEvent) => {
        const rawValue = (e.target as HTMLInputElement).value

        if (type === 'number') {
            const numValue = rawValue.replace('.', ',')

            console.log(isNaN(Number(numValue)))

            if (isNaN(Number(numValue))) {
                getValue(rawValue.replace(',', '.'))
            } else {
                /*getValue(Number(numValue))*/
            }
        } else {
            getValue(rawValue)
        }
    }

    return (
        <div
            className={`${className} ${text ? 'h-10' : 'h-8'} ${
                error ? 'animate-shake' : ''
            } relative flex shrink-0 items-center`}
        >
            {text && (
                <div
                    className={`${spanClass} pointer-events-none absolute left-2 rounded bg-white px-1 transition-all duration-200`}
                >
                    {!error && <span className='mr-1'>{text}</span>}
                    {error && <span className='mr-1 text-red-500'>{errorMessage}</span>}
                    {requiredState && <span className='text-red-500'>*</span>}
                </div>
            )}
            <input
                placeholder={placeholder}
                value={value ? String(value) : ''}
                pattern={pattern}
                onChange={parseValue}
                onFocus={triggerFocus}
                onBlur={triggerBlur}
                onClick={handleClick}
                className={`input h-full w-full rounded ${error ? 'border-red-500' : ''}`}
            />
        </div>
    )
}

export default Input
