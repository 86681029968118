import { FormEvent, useState } from 'react'
import api from '../api'

import { IonButton } from '@ionic/react'

import Input from '../components/Core/Input'

const Login = () => {
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1)

    const [phone, setPhone] = useState('+8')
    const [code, setCode] = useState('')

    const [error, setError] = useState('')

    const sendSMS = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const payload = {
            phone
        }

        setLoading(true)

        await api
            .post('/auth/sendSMS', payload)
            .then(() => setStep(2))
            .catch((err) => {
                console.log(err)
                setError('Неверный номер')
            })
            .finally(() => setLoading(false))
    }

    const sendCode = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const payload = {
            phone,
            code
        }

        setLoading(true)

        await api
            .post('/auth/code', payload)
            .then(() => window.location.replace('/'))
            .catch((err) => {
                console.log(err)
                setError('Неверный код')
            })
            .finally(() => setLoading(false))
    }

    return (
        <div className='flex flex-col justify-center items-center ion-padding h-full'>
            <h1 className='mb-6 border-b border-theme-gray-300 pb-6 text-4xl font-bold text-theme-blue'>Car Help</h1>
            <span className='mb-4 text-center text-sm font-normal'>
                Пожалуйста, введите номер мобильного телефона для получения кода
            </span>
            {step === 1 && (
                <form onSubmit={sendSMS} className='grid w-full grid-cols-1'>
                    <Input
                        value={phone}
                        getValue={setPhone}
                        error={error.length > 1}
                        errorMessage={error}
                        text='Номер телефона'
                        className='mb-4 rounded-lg'
                    />
                    <IonButton type='submit' color='primary' className='' disabled={phone.length < 12}>
                        {loading && <div>Отправка...</div>}
                        {!loading && <span>Получить код</span>}
                    </IonButton>
                </form>
            )}
            {step === 2 && (
                <form onSubmit={sendCode} className='grid w-full grid-cols-1'>
                    <Input
                        value={code}
                        getValue={setCode}
                        error={error.length > 1}
                        errorMessage={error}
                        text='Код из SMS'
                        className='mb-4'
                    />
                    <IonButton type='submit' color='success' className=''>
                        Войти
                    </IonButton>
                </form>
            )}
        </div>
    )
}

export default Login
