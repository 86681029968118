import React, { useEffect, useRef, useState } from 'react'

import api from '../api'

import { Service } from '../api/fetchServices'

import {
    DatetimeChangeEventDetail,
    IonButtons,
    IonDatetime,
    IonHeader,
    IonIcon,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
    useIonActionSheet
} from '@ionic/react'

import Rating from './Rating'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import 'swiper/swiper.min.css'

import { IonButton, IonChip, IonContent, IonModal } from '@ionic/react'
import { calendarOutline, navigate, shieldCheckmark, chevronDown } from 'ionicons/icons'

import { ImPhone } from 'react-icons/im'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { BsFillClockFill } from 'react-icons/bs'
import { HiBookmark } from 'react-icons/hi2'

const ServiceCard = ({ id, parent }: { id: number | null; parent: HTMLElement | null }) => {
    const [data, setData] = useState<Service | null>(null)

    const [tab, setTab] = useState<string | undefined>('main')

    const [date, setDate] = useState<string>('undefined')

    const changeDate = (value: string) => {
        if (value) {
            setDate(value)
        }
    }

    const modal = useRef<HTMLIonModalElement>(null)
    const [present] = useIonActionSheet()
    const [showSchedule, setShowSchedule] = useState(false)
    const [showContacts, setShowContacts] = useState(false)
    const [showCalendar, setShowCalendar] = useState(false)

    const closeForm = () => modal.current?.dismiss()

    const toggleCalendar = () => setShowCalendar(!showCalendar)

    const openYandexMap = (lat: number, lon: number) => {
        window.location.replace(`yandexmaps://maps.yandex.ru/?ll=${lon},${lat}&z=12`)
    }
    const openGoogleMap = (lat: number, lon: number) => {
        window.location.replace(`comgooglemaps://?daddr=${lat},${lon}&directionsmode=driving`)
    }
    const openGisMap = (lat: number, lon: number) => {
        window.location.replace(`dgis://2gis.ru/routeSearch/rsType/car/to/${lat},${lon}`)
    }

    const createRoute = () => {
        if (data) {
            present({
                header: 'Выберите приложение',
                buttons: [
                    {
                        text: 'Яндекс Карты',
                        handler: () => openYandexMap(data.x, data.y)
                    },
                    {
                        text: 'Google Карты',
                        handler: () => openGoogleMap(data.x, data.y)
                    },
                    {
                        text: '2Gis Карты',
                        handler: () => openGisMap(data.x, data.y)
                    },
                    {
                        text: 'Отмена',
                        role: 'cancel',
                        data: { action: 'cancel' }
                    }
                ],
                onDidDismiss: ({ detail }) => console.log(detail)
            })
        }
    }

    const addFavorite = async () => {
        await api
            .post(`/services/favorites/${id}`)
            .then(() => console.log('ez'))
            .catch((err) => console.log(err))
    }

    const removeFavorite = async () => {
        await api
            .delete(`/services/favorites/${id}`)
            .then(() => console.log('ez'))
            .catch((err) => console.log(err))
    }

    const toggleFavorite = () => {
        if (data?.favorite) {
            removeFavorite()
            fetchService()
        } else {
            addFavorite()
            fetchService()
        }
    }

    const bookService = async () => {
        const payload = {
            car_id: 1,
            date
        }

        await api
            .post(`/services/${id}`, payload)
            .then(() => console.log('service booked'))
            .catch((err) => console.log(err))
    }

    // const openContact = (number: string) => {
    //     window.open(`tel:${number}`)
    // }

    // const showContacts = () => {
    //     if (data) {
    //         present({
    //             header: 'Контакты сервиса',
    //             buttons: [
    //                 ...data.contacts.map((i) => {
    //                     return {
    //                         text: i,
    //                         handler: () => openContact(i)
    //                     }
    //                 }),
    //                 {
    //                     text: 'Отмена',
    //                     role: 'cancel',
    //                     data: { action: 'cancel' }
    //                 }
    //             ],
    //             onDidDismiss: ({ detail }) => console.log(detail)
    //         })
    //     }
    // }

    const convertMetersToKilometers = (meters: number) => {
        if (meters >= 1000) {
            return (meters / 1000).toFixed() + ' км'
        } else {
            return meters + ' м'
        }
    }

    const fetchService = async () => {
        await api
            .get(`/services/${id}`)
            .then((res) => {
                setData(res.data)
                modal.current?.present()
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        if (id) {
            fetchService()
        }
    }, [id])

    const checkAvailableDays = (dateString: string) => {
        if (!data) return false

        const days = data.timings.map((i) => i.date)

        return days.some((i) => i === dateString)
    }

    return (
        <IonModal ref={modal} presentingElement={parent!} canDismiss={true} onDidDismiss={closeForm}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot='start'>
                        <IonButton onClick={closeForm}>Назад</IonButton>
                    </IonButtons>
                    <IonTitle>Информация</IonTitle>
                </IonToolbar>
            </IonHeader>
            {data && (
                <IonContent className='text-black'>
                    <div className='flex flex-col dark:bg-black'>
                        <div className='relative mb-4'>
                            <Swiper
                                direction='horizontal'
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: true
                                }}
                                spaceBetween={0}
                                slidesPerView={'auto'}
                                centeredSlides={true}
                                className='w-full overflow-hidden shadow-lg rounded-b-xl'
                                modules={[Autoplay]}
                            >
                                {data.images.map((item, index) => (
                                    <SwiperSlide key={index} className='h-[250px]'>
                                        <img src={item} alt='' className='object-cover h-full w-full' />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div
                                onClick={toggleFavorite}
                                className='absolute bg-white p-2 -bottom-4 shadow-xl z-10 right-4 rounded-full'
                            >
                                <HiBookmark
                                    size={24}
                                    className={`shrink-0 ${data.favorite ? 'fill-blue-500' : 'fill-gray-200'}`}
                                />
                            </div>
                        </div>
                        <div className='m-4'>
                            <IonSegment value={tab} swipeGesture={true} onIonChange={(e) => setTab(e.detail.value)}>
                                <IonSegmentButton value='main'>
                                    <IonLabel>Основное</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value='services'>
                                    <IonLabel>Услуги</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value='reviews'>
                                    <IonLabel>Отзывы</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                        </div>
                        {/*Main*/}
                        {tab === 'main' && (
                            <div className='flex flex-col overflow-hidden rounded-t-xl bg-white dark:bg-[#0f0f0f]'>
                                <div className='flex px-4 my-4'>
                                    <img src={data.img} alt='' className='h-24 w-24 mr-4 rounded-lg object-contain' />
                                    <div className='flex flex-col grow'>
                                        <span className='text-2xl font-bold'>{data.name}</span>
                                        <div className='flex text-gray-400 mb-3 font-semibold text-sm items-center'>
                                            <span>{data.rating}</span>
                                            <Rating rating={data.rating} />
                                            <span>({data.rating_amount})</span>
                                        </div>
                                    </div>
                                </div>
                                {/*График*/}
                                <div className='flex mx-4 mb-4'>
                                    <BsFillClockFill
                                        size={18}
                                        className={`mr-2 ${data.schedule.status ? 'fill-green-500' : 'fill-red-500'}`}
                                    />
                                    <div className='flex flex-col border-b pb-4 grow'>
                                        <div
                                            className='flex items-center justify-between'
                                            onClick={() => setShowSchedule(!showSchedule)}
                                        >
                                            <span className='text-sm font-medium'>{data.schedule.title}</span>
                                            {data.schedule.items.length > 0 && <IonIcon icon={chevronDown}></IonIcon>}
                                        </div>
                                        {showSchedule && data.schedule.items.length > 0 && (
                                            <div className='flex flex-col bg-gray-50 p-2 rounded mt-2'>
                                                {data.schedule.items.map((item, index) => (
                                                    <div key={index} className='flex mb-2 last:mb-0 justify-between'>
                                                        <span className='font-medium'>{item.title}</span>
                                                        <span>{item.schedule}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/*Адрес*/}
                                <div className='flex mx-4 mb-4'>
                                    <FaMapMarkerAlt size={18} className='mr-2 fill-blue-500' />
                                    <div className='flex flex-col border-b pb-4 grow'>
                                        <div className='flex text-sm items-center font-medium mb-1'>
                                            {data.address1} - {convertMetersToKilometers(data.distance)} от Вас
                                        </div>
                                        <div className='text-xs mb-2 text-gray-400'>{data.address2}</div>
                                        <div className='flex items-center w-full justify-between'>
                                            <IonButton
                                                color='warning'
                                                size='small'
                                                className='h-8'
                                                onClick={createRoute}
                                            >
                                                <IonIcon slot='start' icon={navigate}></IonIcon>Построить маршрут
                                            </IonButton>
                                        </div>
                                    </div>
                                </div>
                                {/*Контакты*/}
                                <div className='flex mx-4 mb-4'>
                                    <ImPhone size={18} className='mr-2 fill-green-500' />
                                    <div className='flex flex-col border-b pb-4 grow'>
                                        <div
                                            className='flex items-center justify-between'
                                            onClick={() => setShowContacts(!showContacts)}
                                        >
                                            <span className='text-sm font-medium'>Контакты</span>
                                            <IonIcon icon={chevronDown}></IonIcon>
                                        </div>
                                        {showContacts && (
                                            <div className='flex flex-col mt-2'>
                                                {data.contacts.map((item, index) => (
                                                    <a
                                                        key={index}
                                                        href={`tel:${item}`}
                                                        className='flex mb-2 text-sky-500 last:mb-0 font-medium'
                                                    >
                                                        {item}
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/*Запись на прием*/}
                                {!data.record && (
                                    <div className='flex mx-4 mb-4'>
                                        <span className='w-[24px]'></span>
                                        <div className='flex flex-col grow'>
                                            <IonButton
                                                color='primary'
                                                size='default'
                                                disabled={!!data.record}
                                                className='w-full'
                                                onClick={toggleCalendar}
                                            >
                                                <IonIcon slot='start' icon={calendarOutline}></IonIcon>Записаться
                                            </IonButton>
                                        </div>
                                    </div>
                                )}
                                <IonModal
                                    isOpen={showCalendar}
                                    initialBreakpoint={0.75}
                                    breakpoints={[0, 0.75]}
                                    onDidDismiss={toggleCalendar}
                                >
                                    <IonHeader>
                                        <IonToolbar>
                                            <IonButtons slot='start'>
                                                <IonButton onClick={toggleCalendar}>Назад</IonButton>
                                            </IonButtons>
                                            <IonTitle>Выберите дату</IonTitle>
                                        </IonToolbar>
                                    </IonHeader>
                                    <IonContent className='ion-padding'>
                                        <IonDatetime
                                            id='datetime'
                                            locale='ru-RU'
                                            presentation='date-time'
                                            isDateEnabled={checkAvailableDays}
                                            onIonChange={(e) => changeDate(e.detail.value as string)}
                                            className='mb-4 rounded-lg'
                                        ></IonDatetime>
                                        <IonButton
                                            color='success'
                                            size='default'
                                            className='w-full pr-2'
                                            onClick={bookService}
                                        >
                                            Записаться
                                        </IonButton>
                                    </IonContent>
                                </IonModal>
                            </div>
                        )}
                        {/*Services*/}
                        {tab === 'services' && (
                            <div className='flex flex-col rounded-t-xl p-4 bg-white dark:bg-[#0f0f0f]'>
                                <h2 className='font-semibold text-xl mb-2'>О нас</h2>
                                <div
                                    dangerouslySetInnerHTML={{ __html: data.description }}
                                    className='mb-4 text-sm'
                                ></div>
                                <h2 className='font-semibold text-xl mb-2'>Услуги</h2>
                                <Swiper
                                    direction='horizontal'
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: true,
                                        waitForTransition: true
                                    }}
                                    spaceBetween={10}
                                    slidesPerView={'auto'}
                                    centeredSlides={true}
                                    className='w-full mb-4'
                                    modules={[Autoplay]}
                                >
                                    {data.services.map((item, index) => (
                                        <SwiperSlide key={index} className=''>
                                            <div className='flex flex-col items-center py-4 rounded-lg bg-blue-50 text-blue-500'>
                                                <h2 className='mb-2 text-lg font-bold'>{item.title}</h2>
                                                <span>{item.price}</span>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <h2 className='font-semibold text-xl mb-2'>Удобства</h2>
                                <div className='flex flex-wrap'>
                                    {data.advantages.map((item, index) => (
                                        <IonChip key={index} color='primary' className='shrink-0'>
                                            {item}
                                        </IonChip>
                                    ))}
                                </div>
                            </div>
                        )}
                        {tab === 'reviews' && (
                            <div className='flex flex-col rounded-t-xl p-4 bg-white dark:bg-[#0f0f0f]'>
                                {data.reviews.length > 0 && <div>Есть отзывы</div>}
                                {data.reviews.length === 0 && <div>Пока нет отзывов</div>}
                            </div>
                        )}
                        {data.record && (
                            <div className='flex z-10 sticky bottom-0 items-center px-3 py-2 bg-green-200 dark:bg-green-300 justify-between'>
                                <div className='flex text-sm dark:text-black dark:font-medium items-center'>
                                    <IonIcon
                                        slot='start'
                                        icon={shieldCheckmark}
                                        className='text-green-600 mr-2 h-6 w-6'
                                    ></IonIcon>
                                    Ваша запись - {data.record.time}{' '}
                                    {new Date(data.record.day).toLocaleDateString('ru-RU', {
                                        month: 'short',
                                        day: 'numeric'
                                    })}
                                </div>
                                <IonButton color='danger' size='small' onClick={() => {}}>
                                    Отменить
                                </IonButton>
                            </div>
                        )}
                    </div>
                </IonContent>
            )}
        </IonModal>
    )
}

export default ServiceCard
