import React from 'react'

import { IonIcon } from '@ionic/react'
import { star, starHalf, starOutline } from 'ionicons/icons'

const Rating = ({ rating }: { rating: number }) => {
    const fullStars = Math.floor(rating)
    const halfStars = Math.round(rating - fullStars)
    const emptyStars = 5 - fullStars - halfStars

    return (
        <div className='text-yellow-400 flex mx-1'>
            {[...Array(fullStars)].map((_, i) => (
                <IonIcon key={i} icon={star} />
            ))}
            {[...Array(halfStars)].map((_, i) => (
                <IonIcon key={i + fullStars} icon={starHalf} />
            ))}
            {[...Array(emptyStars)].map((_, i) => (
                <IonIcon key={i + fullStars + halfStars} icon={starOutline} />
            ))}
        </div>
    )
}

export default Rating
