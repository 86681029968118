import React, { useRef, useState, useEffect } from 'react'

import api from '../api'

import {
    IonButton,
    IonButtons,
    IonCard,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar
} from '@ionic/react'

import { addOutline, closeCircleOutline } from 'ionicons/icons'

interface CarTypes {
    id: 13
    vin: string
    gos_number: string
    brand: string
    name: string
    img: string
    year: number
}

interface OrderTypes {
    id: string
    date: Date
    car: { id: string; brand: string; name: string; year: string }
    category: string
    description: string
    hide_number: boolean
    images: '[]'
    status: number
}

const statuses = [
    {
        id: 0,
        name: 'Удален'
    },
    {
        id: 1,
        name: 'Новый заказ'
    },
    {
        id: 2,
        name: 'Есть ответы'
    },
    {
        id: 3,
        name: 'Выполнен'
    }
]

// interface CarsTypes {
//     items: CarTypes[]
// }

const Parts = () => {
    const page = useRef(null)

    const [parentElement, setParentElement] = useState<HTMLElement | null>(page.current)

    useEffect(() => {
        setParentElement(page.current)
    }, [])

    const [toggleForm, setToggleForm] = useState(false)

    const [orders, setOrders] = useState<OrderTypes[]>([])
    const [cars, setCars] = useState<CarTypes[]>([])
    const [categories, setCategories] = useState<{ id: number; name: string }[]>([])

    const [category, setCategory] = useState('')
    const [car, setCar] = useState('')
    const [description, setDescription] = useState('')

    const clearForm = () => {
        setDescription('')
    }

    const changeCategory = (event: any) => {
        setCategory(event.target.value.id)
    }

    const changeCar = (event: any) => {
        setCar(event.target.value)
    }

    const changeDescription = (e: Event) => {
        const value = (e.target as HTMLIonInputElement).value as string

        setDescription(value)
    }

    // const validateForm =
    //     carBrand.length > 0 && carModel.length > 0 && carGovNumber.length > 0 && carVinNumber.length > 0 && carYear

    const openForm = () => setToggleForm(true)
    const closeForm = () => {
        setToggleForm(false)
        clearForm()
    }

    const sendRequest = async () => {
        const payload = {
            car_id: car,
            description,
            category,
            images: [],
            hide_number: false
        }

        await api
            .post(`/parts`, payload)
            .then(() => {
                fetchCars()
            })
            .catch((err) => console.log(err))

        closeForm()
    }

    const fetchOrders = async () => {
        await api
            .get(`/parts`)
            .then((res) => {
                console.log({ parts: res.data })

                setOrders(res.data.items)
            })
            .catch((err) => console.log(err))
    }

    const fetchCars = async () => {
        await api
            .get(`/cars`)
            .then((res) => {
                setCars(res.data.items)
            })
            .catch((err) => console.log(err))
    }

    const fetchCategories = async () => {
        await api
            .get(`/parts/categories`)
            .then((res) => {
                setCategories(res.data)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchOrders()
        fetchCars()
        fetchCategories()
    }, [])

    return (
        <IonPage ref={page}>
            <IonToolbar>
                <IonTitle>Подбор автозапчастей</IonTitle>
                <IonButtons slot='primary'>
                    <IonButton onClick={openForm}>
                        <IonIcon slot='icon-only' icon={addOutline}></IonIcon>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent fullscreen>
                <div className='flex flex-col'>
                    {orders.map((item) => (
                        <IonCard key={item.id} className='relative ion-padding mb-0 flex'>
                            <div className='flex flex-col'>
                                <span className='font-semibold mb-auto line-clamp-2'>
                                    {new Date(item.date).toLocaleDateString('ru-RU', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}
                                </span>
                                <span className='text-sm text-theme-gray-500'>
                                    <span className='font-semibold'>Авто:</span> {item.car.brand} {item.car.name}{' '}
                                    {item.car.year}
                                </span>
                                <div className='text-sm text-theme-gray-500'>
                                    <span className='font-semibold'>Статус: </span>
                                    {statuses.find((i) => i.id == item.status)?.name}
                                </div>
                                <span className='text-sm text-theme-gray-500'>
                                    <span className='font-semibold'>Категория: </span>
                                    {item.category}
                                </span>
                                <span className='text-sm text-theme-gray-500'>
                                    <span className='font-semibold'>Описание: </span>
                                    {item.description}
                                </span>
                                <span className='text-sm text-theme-gray-500'>
                                    <span className='font-semibold'>Номер скрыт: </span>
                                    {item.hide_number}
                                </span>
                            </div>
                            <div className='absolute top-3 right-3'>
                                <IonIcon
                                    icon={closeCircleOutline}
                                    className='text-xl text-red-500'
                                    onClick={() => console.log(item.id)}
                                />
                            </div>
                        </IonCard>
                    ))}
                </div>
                <IonModal
                    isOpen={toggleForm}
                    presentingElement={parentElement!}
                    canDismiss={true}
                    onDidDismiss={closeForm}
                >
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot='start'>
                                <IonButton onClick={closeForm}>Назад</IonButton>
                            </IonButtons>
                            <IonTitle>Новая заявка</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className='text-black'>
                        <IonList className='pr-4'>
                            {categories.length > 0 && (
                                <IonItem>
                                    <IonSelect
                                        interface='popover'
                                        placeholder='Категория запчастей'
                                        onIonChange={changeCategory}
                                    >
                                        {categories.map((item, index) => (
                                            <IonSelectOption key={index} value={item}>
                                                {item.name}
                                            </IonSelectOption>
                                        ))}
                                    </IonSelect>
                                </IonItem>
                            )}
                            {cars.length > 0 && (
                                <IonItem>
                                    <IonSelect interface='popover' placeholder='Выберите авто' onIonChange={changeCar}>
                                        {cars.map((item, index) => (
                                            <IonSelectOption key={index} value={item.id}>
                                                {item.brand} {item.name}
                                            </IonSelectOption>
                                        ))}
                                    </IonSelect>
                                </IonItem>
                            )}
                            <IonItem>
                                <IonLabel>Описание</IonLabel>
                                <IonInput
                                    value={description}
                                    placeholder='Дополнительные детали'
                                    onIonInput={changeDescription}
                                ></IonInput>
                            </IonItem>
                        </IonList>
                        <div className='flex flex-col ion-padding'>
                            <IonButton className='mt-8 w-full' onClick={sendRequest}>
                                Добавить
                            </IonButton>
                        </div>
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    )
}

export default Parts
