import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { fetchServices } from '../api/fetchServices'

import {
    IonAvatar,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonSearchbar,
    IonSegment,
    IonSegmentButton
} from '@ionic/react'

import GoogleMaps from '../components/GoogleMaps'
import ServiceCard from '../components/ServiceCard'

const Map = ({ type }: { type: string }) => {
    const page = useRef(null)

    const [parentElement, setParentElement] = useState<HTMLElement | null>(page.current)
    const [selectedService, setSelectedService] = useState<number | null>(null)
    const [view, setView] = useState('map')
    const [search, setSearch] = useState('')

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['services'],
        queryFn: () => fetchServices({ type })
    })

    const searchResults = data?.items.filter((i) => i.name.toLowerCase().includes(search.toLowerCase()))

    const selectView = (value: string) => {
        setView(value)
    }

    const showSelectedService = (id: number) => {
        setSelectedService(id)
    }

    const changeSearch = (e: Event) => {
        const value = (e.target as HTMLIonInputElement).value as string

        setSearch(value)
    }

    useEffect(() => {
        refetch()
    }, [type])

    useEffect(() => {
        setParentElement(page.current)
    }, [])

    return (
        <>
            <IonPage ref={page} id='main-content'>
                <IonContent fullscreen>
                    <div className='flex items-center px-10 py-2'>
                        <IonSegment value={view} swipeGesture={true} onIonChange={(e) => selectView(e.detail.value!)}>
                            <IonSegmentButton value='map'>
                                <IonLabel>Карта</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value='list'>
                                <IonLabel>Список</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </div>
                    <GoogleMaps
                        markers={
                            data?.items.map((i) => ({ id: i.id, lat: i.x, lng: i.y, name: i.name, icon: i.img })) ?? []
                        }
                        show={view == 'map'}
                        handleSelect={showSelectedService}
                        handleDragStart={() => {}}
                        handleDragEnd={() => {}}
                    />
                    {view == 'list' && (
                        <IonList className='px-4'>
                            <IonSearchbar value={search} placeholder='Поиск' onIonChange={changeSearch}></IonSearchbar>
                            {searchResults?.map((item) => (
                                <IonItem key={item.id} detail={true} onClick={() => showSelectedService(item.id)}>
                                    <IonAvatar slot='start'>
                                        <img src={item.img} alt="Silhouette of a person's head" />
                                    </IonAvatar>
                                    <IonLabel>{item.name}</IonLabel>
                                </IonItem>
                            ))}
                        </IonList>
                    )}
                    {!isLoading && <ServiceCard id={selectedService} parent={parentElement} />}
                </IonContent>
            </IonPage>
        </>
    )
}

export default Map
