import { IonApp, IonIcon, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, setupIonicReact } from '@ionic/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { IonReactRouter } from '@ionic/react-router'
import { Route, Redirect } from 'react-router'
import { useState } from 'react'

import Map from './pages/Map'
import Chat from './pages/Chat'
import Home from './pages/Home'
import Login from './pages/Login'
import Parts from './pages/Parts'
import Garage from './pages/Garage'
import Profile from './pages/Profile'

import { homeOutline, carSport, personCircleOutline, chatbubbleEllipsesOutline } from 'ionicons/icons'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './index.css'
import './theme/variables.css'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: { refetchOnWindowFocus: false }
    }
})

setupIonicReact({ mode: 'ios' })

const App = () => {
    // useEffect(() => {
    //     document.addEventListener(
    //         'touchmove',
    //         function (e) {
    //             e.preventDefault()
    //         },
    //         { passive: false }
    //     )
    //
    //     return () => {
    //         document.removeEventListener('touchmove', function (e) {
    //             e.preventDefault()
    //         })
    //     }
    // }, [])

    const [type, setType] = useState('')

    return (
        <QueryClientProvider client={queryClient}>
            <IonApp>
                <IonReactRouter>
                    <IonTabs>
                        <IonRouterOutlet id='main'>
                            <Redirect exact path='/' to='/home' />
                            <Route path='/home'>
                                <Home changeType={setType} />
                            </Route>
                            <Route exact path='/login'>
                                <Login />
                            </Route>
                            <Route exact path='/map'>
                                <Map type={type} />
                            </Route>
                            <Route exact path='/garage'>
                                <Garage />
                            </Route>
                            <Route exact path='/parts'>
                                <Parts />
                            </Route>
                            <Route exact path='/chat'>
                                <Chat />
                            </Route>
                            <Route exact path='/profile'>
                                <Profile />
                            </Route>
                        </IonRouterOutlet>
                        <IonTabBar slot='bottom'>
                            <IonTabButton tab='home' href='/home'>
                                <IonIcon icon={homeOutline} />
                            </IonTabButton>
                            <IonTabButton tab='garage' href='/garage'>
                                <IonIcon icon={carSport} />
                            </IonTabButton>
                            <IonTabButton tab='chat' href='/chat'>
                                <IonIcon icon={chatbubbleEllipsesOutline} />
                            </IonTabButton>
                            <IonTabButton tab='profile' href='/profile'>
                                <IonIcon icon={personCircleOutline} />
                            </IonTabButton>
                        </IonTabBar>
                    </IonTabs>
                </IonReactRouter>
            </IonApp>
        </QueryClientProvider>
    )
}

export default App
