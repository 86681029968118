import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import api from '../api'

import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonChip,
    IonContent,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react'
import { VscBell } from 'react-icons/vsc'
import { MdAccessTimeFilled, MdShower } from 'react-icons/md'
import { GiFlatTire } from 'react-icons/gi'
import { FaOilCan } from 'react-icons/fa'
import { addOutline } from 'ionicons/icons'

const partsStatuses = [
    {
        id: 0,
        name: 'Удален'
    },
    {
        id: 1,
        name: 'Новый заказ'
    },
    {
        id: 2,
        name: 'Есть ответы'
    },
    {
        id: 3,
        name: 'Выполнен'
    }
]

const serviceStatuses = [
    {
        id: 0,
        name: 'Запись отменена'
    },
    {
        id: 1,
        name: 'Успешная запись'
    },
    {
        id: 2,
        name: 'Выполнен'
    }
]

interface HistoryTypes {
    count: string
    items: {
        id: string
        date: string
        status: string
        type: string
        car: { id: string; brand: string; name: string; year: string }
    }[]
}

interface HomeTypes {
    changeType: (i: string) => void
}

const Home = ({ changeType }: HomeTypes) => {
    const findStatus = (type: string, status: number) => {
        switch (type) {
            case 'parts':
                return partsStatuses.find((i) => i.id == status)?.name
            case 'service':
                return serviceStatuses.find((i) => i.id == status)?.name
        }
    }

    const [history, setHistory] = useState<HistoryTypes>({
        count: '0',
        items: []
    })

    const fetchHistory = async () => {
        await api
            .get(`/history`)
            .then((res) => {
                console.log(res.data)
                setHistory(res.data)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchHistory()
    }, [])

    return (
        <IonPage>
            <IonContent fullscreen>
                <div className='flex flex-col'>
                    <IonCard>
                        <Link to={'/parts'} className='flex flex-col ion-padding items-center bg-white'>
                            <h2 className='font-bold text-xl text-black mb-4'>Подбор автозапчастей</h2>
                            <img src={require('../assets/images/car_parts.jpg')} alt='' />
                        </Link>
                    </IonCard>
                    <IonCard className='grid mt-0 text-center grid-cols-3 gap-4'>
                        <Link
                            to={'/map'}
                            onClick={() => changeType('wash')}
                            className='flex flex-col justify-center items-center py-3 rounded-lg'
                        >
                            <MdShower size={32} className='mb-2 text-blue-500' />
                            <span className='text-sm'>Автомойки</span>
                        </Link>
                        <Link
                            to={'/map'}
                            onClick={() => changeType('tire')}
                            className='flex flex-col justify-center items-center py-3 rounded-lg'
                        >
                            <GiFlatTire size={32} className='mb-2 text-black dark:text-white' />
                            <span className='text-sm'>Шиномонтаж</span>
                        </Link>
                        <Link
                            to={'/map'}
                            onClick={() => changeType('oil')}
                            className='flex flex-col justify-center items-center py-3 rounded-lg'
                        >
                            <FaOilCan size={32} className='mb-2 text-yellow-400' />
                            <span className='text-sm'>Замена масла</span>
                        </Link>
                    </IonCard>
                    {history.items.length > 0 && (
                        <IonCard className='mt-0'>
                            <h2 className='font-semibold flex items-center px-2 py-2'>
                                <MdAccessTimeFilled size={20} className='text-blue-500 mr-1' />
                                <span className='text-xl'>Недавние</span>
                            </h2>
                            <div className='grid gap-4'>
                                {history.items.map((item) => (
                                    <div
                                        key={item.id}
                                        className='rounded-lg flex items-center justify-between text-sm py-1 pl-3 pr-1'
                                    >
                                        <span>{findStatus(item.type, +item.status)}</span>
                                        <IonChip color='primary' className='shrink-0'>
                                            {new Date(item.date).toLocaleDateString('ru', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric'
                                            })}
                                        </IonChip>
                                    </div>
                                ))}
                            </div>
                        </IonCard>
                    )}
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Home
