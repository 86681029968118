import React, { useState, useEffect } from 'react'
import api from '../api'

import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonPage, IonToggle } from '@ionic/react'

import { HiBookmark } from 'react-icons/hi2'
import { BsChatDotsFill } from 'react-icons/bs'
import { MdEdit, MdKeyboardArrowRight, MdOutlineAccessTimeFilled } from 'react-icons/md'
import { IoExitOutline, IoNotifications } from 'react-icons/io5'

interface UserTypes {
    phone: string
    name: string
    birthday: null
}

const Profile: React.FC = () => {
    const [profile, setProfile] = useState()

    const fetchProfile = async () => {
        await api
            .get(`/profile`)
            .then((res) => {
                console.log(res.data)
                setProfile(res.data)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchProfile()
    }, [])

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonCard>
                    <IonCardHeader>
                        <div className='flex'>
                            <div className='w-20 mr-4 h-20 rounded-full overflow-hidden'>
                                <img src='https://i.pravatar.cc/300' alt='' className='object-cover' />
                            </div>
                            <div className='flex flex-col'>
                                <IonCardTitle className='mb-2'>@MeonisRP</IonCardTitle>
                                <IonCardSubtitle>+37379879668</IonCardSubtitle>
                            </div>
                            <div className='absolute right-0 top-3'>
                                <MdEdit size={18} className='mr-2 shrink-0 fill-blue-500' />
                            </div>
                        </div>
                    </IonCardHeader>
                </IonCard>
                <IonCard className='pt-4'>
                    <div className='flex mx-4 mb-4'>
                        <MdOutlineAccessTimeFilled size={24} className='mr-4 shrink-0 fill-yellow-500' />
                        <div className='flex flex-col border-b dark:border-white/25 pb-4 grow'>
                            <div className='flex items-center justify-between'>
                                <span className='text-base font-medium'>История</span>
                                <MdKeyboardArrowRight size={24} className='text-gray-400' />
                            </div>
                        </div>
                    </div>
                    <div className='flex mx-4 mb-4'>
                        <HiBookmark size={24} className='mr-4 shrink-0 fill-blue-500' />
                        <div className='flex flex-col grow'>
                            <div className='flex items-center justify-between'>
                                <span className='text-base font-medium'>Избранное</span>
                                <MdKeyboardArrowRight size={24} className='text-gray-400' />
                            </div>
                        </div>
                    </div>
                </IonCard>
                <IonCard className='pt-4'>
                    <div className='flex mx-4 mb-4'>
                        <IoNotifications size={24} className='mr-4 shrink-0 fill-red-500' />
                        <div className='flex flex-col border-b dark:border-white/25 pb-4 grow'>
                            <div className='flex items-center h-5 justify-between'>
                                <span className='text-base font-medium'>Уведомления</span>
                                <IonToggle slot='end' color='success'></IonToggle>
                            </div>
                        </div>
                    </div>
                    <div className='flex mx-4 mb-4'>
                        <BsChatDotsFill size={24} className='mr-4 shrink-0 fill-green-500' />
                        <div className='flex flex-col border-b dark:border-white/25 pb-4 grow'>
                            <div className='flex items-center justify-between'>
                                <span className='text-base font-medium'>Поддержка</span>
                                <MdKeyboardArrowRight size={24} className='text-gray-400' />
                            </div>
                        </div>
                    </div>
                    <div className='flex mx-4 mb-4'>
                        <IoExitOutline size={24} className='mr-4 shrink-0 text-red-500' />
                        <div className='flex flex-col grow'>
                            <div className='flex items-center justify-between'>
                                <span className='text-base font-medium text-red-500'>Выйти из аккаунта</span>
                                <MdKeyboardArrowRight size={24} className='text-gray-400' />
                            </div>
                        </div>
                    </div>
                </IonCard>
            </IonContent>
        </IonPage>
    )
}

export default Profile
