import { useMemo } from 'react'

import { GoogleMap, useLoadScript, Marker, MarkerClusterer } from '@react-google-maps/api'

interface GoogleMapsProps {
    markers: {
        id: number
        lat: number
        lng: number
        name: string
        icon: string
    }[]
    show: boolean
    handleSelect: (id: number) => void
    handleDragStart: () => void
    handleDragEnd: () => void
}

const GoogleMaps = ({ markers, show, handleSelect }: GoogleMapsProps) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyCamFbFcLPv8de5Ox1RhDoEN4h-F4yvOFs'
    })

    const MapOptions = useMemo(() => {
        return {
            center: { lat: 55.091412, lng: 82.953614 },
            zoom: 12,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            rotateControl: true,
            styles: [
                {
                    featureType: 'poi.business',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'poi.government',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'poi.school',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                }
            ]
        }
    }, [])

    const clusterOptions = useMemo(() => {
        return {
            // gridSize: 8,
            minimumClusterSize: 3
        }
    }, [])

    if (!isLoaded) return <div>Loading...</div>

    return (
        <GoogleMap
            options={MapOptions}
            mapContainerClassName={`h-[calc(100%-48px)] w-full ${show ? 'block' : 'hidden'}`}
        >
            {markers.length > 0 && (
                <MarkerClusterer options={clusterOptions}>
                    {(clusterer) => (
                        <>
                            {markers.map((i) => (
                                <Marker
                                    key={i.id}
                                    position={{ lat: i.lat, lng: i.lng }}
                                    icon={{
                                        url: i.icon,
                                        scaledSize: new google.maps.Size(40, 40),
                                        anchor: new google.maps.Point(20, 20)
                                    }}
                                    clusterer={clusterer}
                                    title='Тестовый маркер'
                                    clickable={true}
                                    shape={{
                                        type: 'circle',
                                        coords: [20, 20, 20]
                                    }}
                                    onClick={() => handleSelect(i.id)}
                                />
                            ))}
                        </>
                    )}
                </MarkerClusterer>
            )}
        </GoogleMap>
    )
}

export default GoogleMaps
