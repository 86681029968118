import axios from 'axios'

export interface Timing {
    date: string
    time: { time: string; available: boolean }[]
}

export interface Schedule {
    status: number
    title: string
    items: { title: string; schedule: string }[]
}

export interface Service {
    id: number
    type: string
    name: string
    description: string
    address1: string
    address2: string
    img: string
    images: string[]
    distance: number
    x: number
    y: number
    favorite: boolean
    rating: number
    rating_amount: number
    reviews: []
    contacts: string[]
    timings: Timing[]
    schedule: Schedule
    record: {
        day: string
        time: string
    }
    advantages: string[]
    services: {
        title: string
        price: string
    }[]
}

export interface Services {
    items: Service[]
    servicesAmount: {
        wash: number
        tire: number
        oil: number
    }
}

export const fetchServices = async ({ type }: { type: string }) => {
    const query = Object.entries({
        type,
        search: ''
    }).reduce((i: any, [x, y]) => (y === '' ? i : ((i[x] = y), i)), {})

    const res = await axios
        .get<Services>('/services', {
            params: query
        })
        .catch((err) => console.log(err))

    return res!.data
}

export const fetchService = async (id: number) => {
    const res = await axios.get<Service>(`/services/${id}`).catch((err) => console.log(err))

    return res!.data
}
