import React from 'react'

import { IonAvatar, IonContent, IonHeader, IonItem, IonLabel, IonPage, IonTitle, IonToolbar } from '@ionic/react'

const Chat: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Сообщения</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse='condense'>
                    <IonToolbar>
                        <IonTitle size='large'>Сообщения</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonAvatar slot='start'>
                            <img alt="Silhouette of a person's head" src='https://i.pravatar.cc/300' />
                        </IonAvatar>
                        <IonLabel>Григорий Лепс</IonLabel>
                    </IonItem>
                </>
            </IonContent>
        </IonPage>
    )
}

export default Chat
