import axios from 'axios'

const api = axios.create({
    baseURL: 'https://carhelp.intercom-ukg.kz/app/v1',
    withCredentials: true
})

api.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response.status === 401) {
            window.location.replace('/login')
        }
        return Promise.reject(error)
    }
)

export default api
